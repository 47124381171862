import { message } from "antd";
import axios from "axios";

// axios interceptor
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["X-FS-Authorization"] = localStorage.getItem("token");
axios.defaults.transformRequest = function (data, headers) {
  if (!headers.token) {
    headers["X-FS-Authorization"] = localStorage.getItem("token");
  }
  return headers["Content-Type"] === "application/json"
    ? JSON.stringify(data)
    : data;
};

axios.interceptors.request.use(
  (config) =>
    // Do something before request is sent
    config,
  (error) => {
    console.log(error);
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    try {
      if (!navigator.onLine) {
        message.warning("No Internet connection. Please try again.");
        return Promise.reject(error);
      }
      if (error.response && error.response.status === 401) {
        if (
          window?.location?.pathname === "/" ||
          window?.location?.pathname === "/forgot-password"
        ) {
          return Promise.reject(
            error?.response?.data?.message ||
              "Something went wrong. Please try again."
          );
        }
        localStorage.clear();
        window.location.href = "/";
      } else if (error.request) {
        if (error.request.status === 500) {
          message.warning(
            error?.response?.data?.message ||
              "Something went wrong. Please try again."
          );
        }
        if (error.request.status === 404) {
          console.log(error.response);
          // message.warning(
          //   error?.response?.data?.message ||
          //     "Something went wrong. Please try again."
          // );
        }
        // message.warning(error.message || 'Something went wrong')
        return Promise.reject(
          error?.response?.data?.message ||
            "Something went wrong. Please try again."
        );
      } else {
        // Something happened in setting up the request that triggered an Error

        console.log(
          "Error",
          error.message ||
            error?.response?.data?.message ||
            "Something went wrong. Please try again."
        );
        return Promise.reject(error);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
