const accountState = {
  userInfo: {
    name: "",
    email: "",
    phone_number: "",
    role: ""
  },
  companyInfo: {
    org_name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    postal_code: ""
  }
};

export default function accountReducer(state = accountState, action) {
  switch (action.type) {
    case "user/updateInfo": {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          name: action.payload.name,
          email: action.payload.email,
          phone_number: action.payload.phone_number,
          role: action.payload.role
        }
      };
    }
    case "company/updateInfo": {
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          org_name: action.payload.org_name,
          address: action.payload.address,
          city: action.payload.city,
          state: action.payload.state,
          country: action.payload.country,
          postal_code: action.payload.postal_code
        }
      };
    }
    default: {
      return state;
    }
  }
}
